import {
   type EligibilityResponseDataDto,
   type PayerResponseDto,
   type SoharInsuranceRequestDto,
   type SoharVerificationIdentifier,
   type SoharVerificationResultResponseDto,
   type SoharWebhookResponseDto,
   getEligibilityIntakeSchema,
   getEligibilitySchema,
   getPayersSchema,
   soharVerificationSchema,
   startSoharVerificationSchema,
} from '@innerwell/dtos';
import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();
export const insurance = c.router(
   {
      getPayers: {
         method: 'GET',
         path: '/insurance/payers',
         query: getPayersSchema,
         responses: {
            200: c.type<PayerResponseDto[]>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },

      /**
       * @deprecated
       * To be removed once Sohar implementation is done
       */
      getEligibility: {
         method: 'POST',
         path: '/insurance/eligibility',
         body: getEligibilitySchema,
         responses: {
            200: c.type<EligibilityResponseDataDto>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },
      /**
       * @deprecated
       * To be removed once Sohar implementation is done
       */
      getEligibilityInIntake: {
         method: 'POST',
         path: '/insurance/eligibility/intake',
         body: getEligibilityIntakeSchema,
         responses: {
            200: c.type<EligibilityResponseDataDto>(),
         },
      },

      startEligibilityVerification: {
         method: 'POST',
         path: '/insurance/eligibility/startVerification',
         body: startSoharVerificationSchema,
         responses: {
            200: c.type<SoharVerificationIdentifier>(),
         },
      },
      getEligibilityVerificationStatus: {
         method: 'GET',
         path: '/insurance/eligibility/verificationStatus',
         responses: {
            200: c.type<SoharInsuranceRequestDto>(),
         },
      },
      getEligibilityVerificationResults: {
         method: 'GET',
         path: '/insurance/eligibility/verificationResults',
         query: soharVerificationSchema,
         responses: {
            200: c.type<SoharVerificationResultResponseDto>(),
         },
      },
      webhookEligibilityVerificationResults: {
         method: 'POST',
         path: '/insurance/eligibility/webhook',
         body: c.type<SoharWebhookResponseDto>(),
         responses: {
            200: z.void(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
